import React from 'react'
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const iconImage = require("./test-img/portfolio-23.jpg");
const screenShot1 = require("./test-img/devkick-1.png");


export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const DevKick = () => {
    useTitle("devKick - RISE")
    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
            <Header />


            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                         <h2 style={{ fontSize: "20px" }}>devKick</h2>
                        <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>

                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '100px', paddingTop: '0.98px' }}>SOFTWARE</div>

                            <div className='left-tag' style={{ background: 'red', width: '40px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>NPM</div>

                        </div>

                    </div>
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the NPM Package</h2>
                    <p style={{ fontSize: '18px' }}>
                        devKick is a colorful, interactive command-line tool designed to help developers kickstart their projects with ease. Whether you're working with Docker, web apps, or Python, DevKick checks your environment, suggests fixes, and runs startup tasks—all from your terminal.
                    </p>
                    <br></br>

                </div>
                <h2>Media</h2>

                <div className="screenshot-container-sideways">

                    <img src={screenShot1} alt='DEVKICK_SCREENSHOT_1' />


                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: March 9, 2025</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red", fontWeight: 'bold' }}>THE RISE COLLECTION</a></li>                    </ul>
                </div>
                <br></br>
                <h2>Download</h2>

                <div className="game-description">
                    <p>Find this software on the following platforms:</p>
                    <ul style={{ fontSize: '18px' }}>
                        <li><a href='https://www.npmjs.com/package/devkick' style={{ color: 'red', fontWeight: 'bold' }}>NPMJS</a></li>
                    </ul>

                </div>
            </div>
            <br></br>
            <div style={{ backgroundColor: 'black', }}>
                <div className="footer-content" style={{ backgroundColor: 'black' }}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default DevKick
