import React from 'react'
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Header from '../components/Header';

const iconImage = require("./test-img/portfolio-18.jpg");
const screenShot1 = require("./test-img/spookysword.png");
const footerLogo = require("./test-img/logo.png");


export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const SpookySword = () => {
    useTitle("Spooky Sword - RISE")

    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
       <Header/>
            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                         <h2 style={{ fontSize: "20px" }}>Banjo</h2>
                        <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px', width: '75px' }}>ASSET</div>
                            <div className='left-tag' style={{ background: 'red', width: '100px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>3D MODEL</div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Asset</h2>
                    <p style={{ fontSize: '18px' }}>
                        "Spooky Sword" is a game ready 3D asset created using Blender. This asset makes an apperance on Nifty Island as an in-game weapon. Spooky Sword was one of the first 3D models created during the "portfolio expansion"...
                    </p>
                  
                    <br></br>

                </div>
                <h2>Media</h2>

                <div className="screenshot-container-sideways">

                    <img src={screenShot1} alt='HELLO_WORLD_SCREENSHOT_1' />



                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: N/A</li>
                        <li>Developer: <a href="https://www.linkedin.com/in/michael-lambooy-9ab15a187/"
                            style={{ color: "red", fontWeight: 'bold' }}>MICHAEL LAMBOOY</a></li>              
                            
                            <li>Category: 3D</li>

                                 </ul>
                </div>
                <br></br>
                <h2>Explore</h2>

                <div className="game-description">

                <p>Find this asset on the following platforms:</p>
                    <ul style={{ fontSize: '18px' }}>
                        <li><a href='https://www.niftyisland.com/item/polygon/0xc1374b803dfb1a9c87eab9e76929222dba3a8c39/64354137439761569121662543683618657866846691657834787287358277870425034719252' style={{color: 'red', fontWeight: 'bold'}}>NIFTY ISLAND</a></li>
                    </ul>                </div>
            </div>
            <br></br>
            <div style={{ backgroundColor: 'black', }}>


                <div className="footer-content" style={{ backgroundColor: 'black' }}>
                    <footer className="footer">
                    <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <footer className="footer">
            <div className="footer-content" style={{ paddingBottom: "5px" }}>
              <div className="footer-social">
                <img alt="rise_logo" src={footerLogo} style={{ width: "75px" }} />
             
              <div className='footerLinks'>
                <div className='footerLinkA'><a href='/social' >SOCIAL</a></div>
                <div className='footerLinkA'><a href='/privacy-policy'>PRIVACY POLICY</a></div>
                <div className='footerLinkA'><a href="https://the-rise-collection.gitbook.io/the-rise-collection">DOCUMENTATION</a></div>
                <div className='footerLinkA'><a href='/#contact-us'>CONTACT US</a></div>

              </div>


                <br />
                <br />
                <div style={{ marginTop: "-30px" }}></div>
              </div>
            </div>
          </footer>
        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default SpookySword