import React from 'react'
import '../../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const iconImage = require("../../script/test-img/portfolio-19.jpg");
const screenShot1 = require("../../script/test-img/mrbird.png");


export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const MrBird = () => {
    useTitle("Mr Bird - RISE")

    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff', overflowX: 'hidden' }}>
           <Header/>
            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                         <h2 style={{ fontSize: "20px" }}>Mr Bird</h2>
                        <p ><a style={{ fontSize: '15px', color: 'red', fontWeight: 'bold' }} href="/#about-us">THE RISE COLLECTION</a></p>
                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px', width: '75px' }}>ASSET</div>
                            <div className='left-tag' style={{ background: 'red', width: '100px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>3D MODEL</div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Asset</h2>
                    <p style={{ fontSize: '18px' }}>
                        This asset is a 3D recreation of "Mr Bird" from our first mobile game AOTC.. AOTC was created in a "drag and drop" coding engine called GameSalad. The app was available from 2015-2019..
                    </p>

                    <br></br>

                </div>
                <h2>Media</h2>

                <div className="screenshot-container-sideways">

                    <img src={screenShot1} alt='HELLO_WORLD_SCREENSHOT_1' />



                </div>
                <br></br>
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: N/A</li>
                        <li>Developer: <a href="https://www.linkedin.com/in/michael-lambooy-9ab15a187/"
                            style={{ color: "red", fontWeight: 'bold' }}>MICHAEL LAMBOOY</a></li>
                                                        <li>Category: 3D</li>

                                             </ul>
                </div>
                <br></br>
                <h2>Explore</h2>

                <div className="game-description">

                    <p>Find this asset on the following platforms:</p>
                    <ul style={{ fontSize: '18px' }}>
                        <li><a href='https://www.niftyisland.com/item/polygon/0xc1374b803dfb1a9c87eab9e76929222dba3a8c39/64354137439761569121662543683618657866846691657848846903546979430713618595950' style={{color: 'red', fontWeight: 'bold'}}>NIFTY ISLAND</a></li>
                    </ul>
                </div>
            </div>
            <br></br>
            <div style={{ backgroundColor: 'black', }}>


            <div className="footer-content" style={{ backgroundColor: 'black' }}>
          <Footer/>
        </div>
            </div>
        </div>
    )
}

export default MrBird